<template>
	<!-- 农业数据统计分析-->
	<div class="land_data_monitoring">
		<!-- 头部 -->
		<div class="land_head">
			<!-- 大棚选择器 -->
			<div class="select">
				<!-- <el-cascader
				    v-model="value"
				    :options="options"
				    :props="{value: 'id', label: 'land_name',children:'children', expandTrigger: 'hover' }"
				    @change="handleChange"
					popper-class="train-tree">
				</el-cascader> -->
				<el-select v-model="value1" clearable  placeholder="请选择" @change="typeChange">
				    <el-option
				      v-for="item in options1"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				    </el-option>
				</el-select>
				<el-select v-model="value2" clearable  placeholder="请选择" @change="landChange">
				    <el-option
				      v-for="item in options2"
				      :key="item.id"
				      :label="item.yard_name"
				      :value="item.id">
				    </el-option>
				</el-select>
				<el-date-picker
				    v-model="time"
				    type="date"
					:picker-options="pickerBeginDateBefore"
					value-format="timestamp"
					@change="handleTimeChange"
				    placeholder="选择日期">
				</el-date-picker>
			</div>
		</div>
		<!-- 中间部分 -->
		<div class="land_middle">
			<!-- 气象环境监测 -->
			<div class="real_time">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>地块环境监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="real_list">
					<div v-for="(item_label,index_label) in labelList" :key='index_label' class="data_item">
					   <div>{{item_label}}<i></i></div>
					   <span class="data_number" v-if="item_label=='空气温度'">{{realObj.kqwd}}°C</span>
					   <span class="data_number" v-if="item_label=='空气湿度'">{{realObj.kqsd}}%</span>
					   <span class="data_number" v-if="item_label=='10cm土壤温度'">{{realObj.trwd10}}°C</span>
					   <span class="data_number" v-if="item_label=='10cm土壤湿度'">{{realObj.trsd10}}%</span>
					   <span class="data_number" v-if="item_label=='20cm土壤温度'">{{realObj.trwd20}}°C</span>
					   <span class="data_number" v-if="item_label=='20cm土壤湿度'">{{realObj.trsd20}}%</span>
					   <span class="data_number" v-if="item_label=='30cm土壤温度'">{{realObj.trwd30}}°C</span>
					   <span class="data_number" v-if="item_label=='30cm土壤湿度'">{{realObj.trsd30}}%</span>
					   <span class="data_number" v-if="item_label=='40cm土壤温度'">{{realObj.trwd40}}°C</span>
					   <span class="data_number" v-if="item_label=='40cm土壤湿度'">{{realObj.trsd40}}%</span>
					   <span class="data_number" v-if="item_label=='60cm土壤温度'">{{realObj.trwd60}}°C</span>
					   <span class="data_number" v-if="item_label=='60cm土壤湿度'">{{realObj.trsd60}}%</span>
					   <span class="data_number" v-if="item_label=='80cm土壤温度'">{{realObj.trwd80}}°C</span>
					   <span class="data_number" v-if="item_label=='80cm土壤湿度'">{{realObj.trsd80}}%</span>
					   <span class="data_number" v-if="item_label=='光合有效辐射'">{{realObj.gh==='null'?0:realObj.gh}}LUX</span>
					   <span class="data_number" v-if="item_label=='二氧化碳浓度'">{{realObj['2y']}}%</span>
					   <span class="data_number" v-if="item_label=='营养液EC值'">{{realObj.ec}}mS/cm</span>
					   <span class="data_number" v-if="item_label=='营养液pH值'">{{realObj.ph}}</span>
					   <span class="data_number" v-if="item_label=='灌溉量'">{{realObj.ggl}}L</span>
					   <span class="data_number" v-if="item_label=='分区灌溉'">{{realObj.fq_gg?realObj.fq_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='日灌溉'">{{realObj.day_gg?realObj.day_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='周灌溉'">{{realObj.week_gg?realObj.week_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='最近灌溉时间'">{{realObj.gg_time}}</span>
					   <span class="data_number" v-if="item_label=='风速'">{{realObj.fs}}m/s</span>
					   <span class="data_number" v-if="item_label=='风向'">{{realObj.fx}}°</span>
					   <span class="data_number" v-if="item_label=='光照强度'">{{realObj.gz}}KLux</span>
					   <span class="data_number" v-if="item_label=='雨量'">{{realObj.yuliang}}mm</span>
					   <span class="data_number" v-if="item_label=='大气压'">{{realObj.dqy}}Kpa</span>
					   <span class="data_number" v-if="item_label=='噪声'">{{realObj.zs}}db</span>
					   <span class="data_number" v-if="item_label=='PM 2.5'">{{realObj.pm2}}ug/m³</span>
					   <span class="data_number" v-if="item_label=='PM 10'">{{realObj.pm10}}ug/m³</span>
					</div>
				</div>
			</div>
			<!-- 图表1 -->
			<div class="video">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>空气温度/湿度监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_ADataAnalysis1" ref='chart'></div>
			</div>
			<!-- 图表2 -->
			<div class="sensor">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<!-- <div v-if="landType===1">10cm土壤温度/湿度监测</div>
						<div v-else>20cm土壤温度/湿度监测</div> -->
						<div >{{txt1}}cm土壤温度/湿度监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_ADataAnalysis2" ref='chart'></div>
				
			</div>
		</div>
		<!-- 底部 -->
		<div class="land_bottom">
			<!-- 图表3 -->
			<div class="real_time">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>光合有效辐射监控</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_ADataAnalysis3" ref='chart_gh'></div>
			</div>
			<!-- 图表4-->
			<div class="day_charts">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<!-- <div>40cm土壤温度/湿度监测</div> -->
						<div >{{txt2}}cm土壤温度/湿度监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_ADataAnalysis4" ref='chart'></div>
			</div>
			<!-- 图表5 -->
			<div class="ny_data">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<!-- <div>60cm土壤温度/湿度监测</div> -->
						<div >{{txt3}}cm土壤温度/湿度监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_ADataAnalysis5" ref='chart'></div>
			</div>
		</div>
	</div>
</template>

<script>
import { sendMessageWebsocket } from '@/api/websocket'
import { getYardList,realtimeDeviceMonitor,getAgriculturalLandMonitor} from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
export default {
  name: 'AgriculturalLandDataMonitoring',
  data(){
	  return{
		 time:'',
		 pickerBeginDateBefore: {
		    disabledDate(time) {
		      return time.getTime() > Date.now();
		    }   
		 },
		 // value:['1','11'],//默认选择的内容
		 // options:[
			//  {
			// 	id: '1',
			// 	  land_name: '日光棚',
			// 	  children: [{
			// 	    id: '11',
			// 	    land_name: '1号日光棚'
			// 	  }, {
			// 	    id: '12',
			// 	    land_name: '2号日光棚'
			// 	  }, {
			// 	    id: '13',
			// 	    land_name: '3号日光棚'
			// 	  }, {
			// 	    id: '14',
			// 	    land_name: '4号日光棚'
			// 	  }]
			// 	},
			// 	{
			// 	   id: '2',
			// 	     land_name: '春秋棚',
			// 	     children: [{
			// 	       id: '21',
			// 	       land_name: '1号春秋棚'
			// 	     }, {
			// 	       id: '22',
			// 	       land_name: '2号春秋棚'
			// 	     }]
			// 	},
			// 	{
			// 	   id: '3',
			// 	     land_name: '大田',
			// 	     children: [{
			// 	       id: '31',
			// 	       land_name: '1号大田'
			// 	     }, {
			// 	       id: '32',
			// 	       land_name: '2号大田'
			// 	     }, {
			// 	       id: '33',
			// 	       land_name: '3号大田'
			// 	     }
			// 		 ]
			// 	}           
		 // ],
		 options1:[
		 		{ value: '1',
		 		  label: '日光棚'
		 		}, {
		 		  value: '2',
		 		  label: '春秋棚'
		 		}, {
		 		  value: '3',
		 		  label: '大田'
		 		}                 
		 ],
		  value1:'',
		  value2:'',
		  options2:[],
		  landType:1,
		 // labelList:this.labelList1,
		 labelList:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList1:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList2:['空气温度','空气湿度','10cm土壤温度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','40cm土壤温度','40cm土壤湿度','60cm土壤温度','60cm土壤湿度','80cm土壤温度','80cm土壤湿度','风速','风向','光合有效辐射','雨量','大气压','噪声','PM 2.5','PM 10','光照强度'],
		 // realObj:this.realObj1,
		 realObj:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',co2:'20',ec:3,ph:7.6,ggl:10,fqgg:1,rgg:1,zgg:7,time:1636522201},
		  realObj1:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',co2:'20',ec:3,ph:7.6,ggl:10,fqgg:1,rgg:1,zgg:7,time:1636522201},
		 realObj2:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',fs:'3',fx:0,gzqd:200,yl:2,dqy:40,zs:40,pm2:36,pm10:50},
		 timeId:[], //定时器集合
		 xAxisName: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24] , // x轴名称
		 // legendList:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','二氧化碳浓度'], //折线名称集合
		 // legendList1:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','二氧化碳浓度'], //折线名称集合
		 // legendList2:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','雨量变化'], //折线名称集合
		 dataList:{},
		 id:1,
		 txt1:10,
		 txt2:20,
		 txt3:30 
	}
  },
  mounted () {
  		 // 获取chart盒子的宽度
  		 this.fullWidth = `${document.querySelector('#myChart_ADataAnalysis1').clientWidth}`// 默认值
  		 const that = this
  		 window.onresize = () => {
  		   return (() => {
  		     // window.fullWidth = document.documentElement.clientWidth
  		     window.fullWidth = document.querySelector('#myChart_ADataAnalysis1').clientWidth
  		     that.fullWidth = window.fullWidth
  		   })()
  		 }
  		console.log('mounted')
  		console.log(this.fullWidth)
		this.getRealData (1)
		this.agriculturalLandMonitor(1)
  		
		
  },
  destroyed() {
  		  console.log('destroyed')
  		  console.log(this.timeId)
  		  // 清空该页面中所有的的定时器
  		  for(let i=0;i<this.timeId.length;i++){
  			   clearInterval(this.timeId[i])
  		  }
		  // 销毁图表实列，释放echarts资源
		  echarts.dispose(this.myChart1);
		  echarts.dispose(this.myChart2);
		  this.myChart1 = null;
		  this.myChart2 = null;
  },
  methods:{
	getLandList(type){
		console.log("获取场地")
		// 获取表格数据
		const data = {
		 type:type
		}
		// 请求数据
		getYardList(data)
		  .then((res) => {
				  console.log(res)
				  console.log(res.data)
				  this.options2=res.data
		  })
		  .catch((err) => {
		    console.log(err)
		  })
	},
	getRealData (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  realtimeDeviceMonitor(data)
	    .then((res) => {
		  console.log("获取地块环境监测数据")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  this.realObj=res.data
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	agriculturalLandMonitor (id) {
	  const data = {
		  yard_id:id,
		  now_time:this.time
	  }
	  // 请求数据
	  getAgriculturalLandMonitor(data)
	    .then((res) => {
		  console.log("获取农业用地数据监测")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  // this.realObj=res.data.info
			  // this.dataList=res.data.datalist
			  this.dataList=res.data
			  this.$nextTick(function () {
			    this.drawLine('myChart_ADataAnalysis1','空气',this.dataList.kqsd,this.dataList.kqwd,1);
			    this.drawLine('myChart_ADataAnalysis2',this.txt1+'cm土壤',this.dataList.trsd20,this.dataList.trwd20,2);
			    this.drawLine2();
			    this.drawLine('myChart_ADataAnalysis4',this.txt2+'cm土壤',this.dataList.trsd40,this.dataList.trwd40,4);
			    this.drawLine('myChart_ADataAnalysis5',this.txt3+'cm土壤',this.dataList.trsd60,this.dataList.trwd60,5);
			   })
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	// 选择框事件
	handleChange(value) {
	    console.log(value);
		// //选择的时大田时用labelList2，realObj2
		this.labelList=value[0]=='3'?this.labelList2:this.labelList1
		this.realObj=value[0]=='3'?this.realObj2:this.realObj1
		this.$forceUpdate()
	},
	// 场地种类选择
	typeChange(value){
		console.log(value)
		this.landType=value
		this.getLandList(value)
	},
	landChange(e){
		console.log(e)
		this.id=e
		this.labelList=this.landType=='3'?this.labelList2:this.labelList1
		this.txt1=this.landType=='3'?20:10,
		this.txt2=this.landType=='3'?40:20,
		this.txt3=this.landType=='3'?60:30 
		this.getRealData (e)
		this.agriculturalLandMonitor (e)
	},
	// 时间选择器
	handleTimeChange(e){
		console.log(e)
		this.agriculturalLandMonitor (this.id)
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 数据监测图表
	drawLine (idName,labelStr,seriesData1,seriesData2,num) {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart1 = echarts.init(document.getElementById(idName));
			           this.myChart1.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#00DDFF', '#FF0087'],
						   // title: {
						   //      text: '24小时数据监测图表',
						   //  	textStyle:{
						   //  		color: '#A5EEEB',
						   //  	},
						   //  },
						    tooltip: {
						      trigger: 'axis',
						      axisPointer: {
						        type: 'cross',
						        label: {
						          backgroundColor: '#6a7985'
						        }
						      },
							  backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							  borderColor:'gray',
							  textStyle:{
							  	align:'left',///标签内容左对齐
							  	color: '#FFFFFF'
							  },
							  
							  // 自定义节点标签内容
							  formatter(params){
							  	  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
							  	  const Y = date.getFullYear() + '年'
							  	  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
							  	  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
							  	  let time= Y + M + D 
							  	  let str=time+'  '+params[0].axisValue+'时'+'<br>'						   
							      params.forEach((item, idx) =>{
							  		str+=item.marker+' '+labelStr+item.seriesName+' : '+item.data
							  		switch (idx){
							  		     case 0:
							  		         str += '%';
							  		         break;
							  		     case 1:
							  		         str += '°C';
							  		         break;
							  		     default:
							  		         str += '';		    
							  		}							
							  		str += idx === params.length -1? '': '<br/>'							   
							      })   
							  		return str;
							   }
						    },
						    legend: {
						      data:['湿度','温度'],
						      textStyle:{
						      	  color: '#FFFFFF'
						      },
						      // orient: 'vertical',  //垂直显示
						      y: '30',    //延Y轴居中
						      x: 'center' ,//居右显示
							  itemWidth:30,//图例的宽度
							  itemHeight:10,//图例的高度
						    },
						    toolbox: {
						      feature: {
						        saveAsImage: {
						    		show:true,
						         backgroundColor :'balck',
						    		pixelRatio: 3
						    	}
						      },
						    },
						    grid: {
						      left: '3%',
						      right: '3%',
						      bottom: '3%',
							  top:'50',
						      containLabel: true
						    },
						    xAxis: [
						      {
						        type: 'category',
						        boundaryGap: false,
								splitLine:{
									show:false
								},
								// 设置坐标轴字体颜色和宽度
								axisLine:{
									lineStyle: {
									    color: '#FFFFFF'
									}	,
								},
						        data: this.xAxisName
						      }
						    ],
						    yAxis: [
						  { // 第一个y轴位置在左侧 湿度
						    position: 'left',
						    type: 'value',
						    name: '%',
						    // name: this.Ystyle(this.yAxisName[0]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]   //想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的名称颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      // color: '#FFFFFF',
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						      // color: ['red'],
						      // width: 0.5,
						      // type: 'solid'
						    }
						  },
						  { // 第二个y轴在右侧
						    position: 'right',
						    type: 'value',
						    name: '°C',
						    // name: this.Ystyle(this.yAxisName[1]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						],
						    series: [
						      {
						        name: '湿度',
						        type: 'line',
								yAxisIndex: 0,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(0, 221, 255)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(77, 119, 255)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
								data: seriesData1
						      },
						      {
						        name: '温度',
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255, 0, 135)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(135, 0, 157)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [120, 282, 111, 234, 220, 340, 310]
								data: seriesData2
						      }
						    ]
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart1.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart1,option,num)
	},
	// 光合有效辐射数据监测图表
	drawLine2 () {
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart2 = echarts.init(document.getElementById('myChart_ADataAnalysis3'));
			           this.myChart2.resize({
			             width: this.$refs.chart_gh.clientWidth,
			             height: this.$refs.chart_gh.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#FFBF00'],
						   // title: {
						   //      text: '24小时数据监测图表',
						   //  	textStyle:{
						   //  		color: '#A5EEEB',
						   //  	},
						   //  },
						    tooltip: {
						      trigger: 'axis',
						      axisPointer: {
						        type: 'cross',
						        label: {
						          backgroundColor: '#6a7985'
						        }
						      },
							  backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							  borderColor:'gray',
							  textStyle:{
							  	align:'left',///标签内容左对齐
							  	color: '#FFFFFF'
							  },
							  
							  // 自定义节点标签内容
							  formatter(params){
							  	  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
							  	  const Y = date.getFullYear() + '年'
							  	  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
							  	  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
							  	  let time= Y + M + D 
							  	  let str=time+'  '+params[0].axisValue+'时'+'<br>'						   
							      params.forEach((item, idx) =>{
							  		str+=item.marker+' '+'光合有效辐射'+item.seriesName+' : '+item.data
							  		switch (idx){
							  		     case 0:
							  		         str += 'LUX';
							  		         break;
							  		     case 1:
							  		         str += '°C';
							  		         break;
							  		     default:
							  		         str += '';		    
							  		}							
							  		str += idx === params.length -1? '': '<br/>'							   
							      })   
							  		return str;
							   }
						    },
						   //  legend: {
						   //    data:['湿度','温度'],
						   //    textStyle:{
						   //    	  color: '#FFFFFF'
						   //    },
						   //    // orient: 'vertical',  //垂直显示
						   //    y: '30',    //延Y轴居中
						   //    x: 'center' ,//居右显示
							  // itemWidth:30,//图例的宽度
							  // itemHeight:10,//图例的高度
						   //  },
						    toolbox: {
						      feature: {
						        saveAsImage: {
						    		show:true,
						         backgroundColor :'balck',
						    		pixelRatio: 3
						    	}
						      },
						    },
						    grid: {
						      left: '3%',
						      right: '3%',
						      bottom: '3%',
							  top:'50',
						      containLabel: true
						    },
						    xAxis: [
						      {
						        type: 'category',
						        boundaryGap: false,
								splitLine:{
									show:false
								},
								// 设置坐标轴字体颜色和宽度
								axisLine:{
									lineStyle: {
									    color: '#FFFFFF'
									}	,
								},
						        data: this.xAxisName
						      }
						    ],
						    yAxis: [
						  { // 第一个y轴位置在左侧 
						    position: 'left',
						    type: 'value',
						    name: 'LUX',
						    // name: this.Ystyle(this.yAxisName[0]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]   //想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的名称颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      // color: '#FFFFFF',
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						      // color: ['red'],
						      // width: 0.5,
						      // type: 'solid'
						    }
						  },
						],
						    series: [
						      {
						        name: '光合有效辐射',
						        type: 'line',
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255, 191, 0)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(224, 62, 76)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
								data:this.dataList.gh
						      },
						    ]
			           };
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart2.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart2,option,3)
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	// 纵坐标的值竖着显示的实现方法
	Ystyle (value) {
	  // console.log(value)
	  if (value) {
	    // 如果传入的值不为空的时候把y轴的文字改为竖版显示
	    const str = value.split('')
	    return str.join('\n')
	  }
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '年'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},
  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullHeight ` 发生改变，这个函数就会运行
    fullHeight: function (val) {
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },
	
  }
}
</script>

<style scoped="scoped">
.land_data_monitoring {
    width: 100%;
    height: 100%;
	padding: 0 30px 30px 30px;
	box-sizing: border-box;
  }
  .land_head{
	  width: 100%;
	  height: 78px;
	  margin-bottom: 30px;
	  display: flex;
	  align-items:flex-end;
	  justify-content: start;
	  position: relative;
  }
  .select{
	  width: 553px;
	  height: 48px;
	  display: flex;
	  align-items: center;
  }
  .land_middle{
	  width: 100%;
	  height:calc(50% - 70px);
	  margin-bottom: 30px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	 /* background-color: #FB5C55; */
  }
  .video{
	  width: calc(35% - 30px);
	  height: 100%;
	  padding: 0 20px;
	  box-sizing: border-box;
	  background: rgba(10, 23, 56, 0.7);
  }
  .sensor{
	  width:calc(35% - 30px) ;
	   height: 100%;
	  background: rgba(10, 23, 56, 0.7);
	  padding: 0 20px;
	  box-sizing: border-box;
	 /* border: 1px solid #36CFC9; */
  }
  .title{
  	  width: 100%;
  	  height: 42px;
  	  display: flex;
  	  align-items: flex-end;
  	  aljustify-content: start;
  	  text-align: left;
	  margin-top: 0;
  }
  .t_l{
  	  width: 33px;
  	  height: 34px;
  	  background: url(../../assets/jiankong/quan_o.png) no-repeat;
  	  margin-right: 14px;
	  background-size: contain;
  }
  .t_r{
  	  width: calc(100% - 47px);
  	  font-size: 16px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  height: 42px;
  	  line-height: 42px;
  }
  .line{
  	  width: 100%;
  	  height: 1px;
  	  background: url(../../assets/jiankong/line.png);
  }
 
  
  .land_bottom{
	 width: 100%;
	 /* height: 445px; */
	 height:calc(50% - 70px);
	 display: flex;
	 justify-content: space-between;
	/* background-color: yellow; */
  }
  .real_time{
	 /* width: 531px; */
	  width: 30%;
	  /* height: 445px; */
	  height: 100%;
	  background: rgba(10,23,56,0.7);
	  padding: 0 20px;
	  box-sizing: border-box;
	 /* border: 1px solid #36CFC9; */
  }
  .real_list{
	  width: 100%;
	  height: calc(100% - 42px);
	  padding-top: 20px;
	  box-sizing: border-box;
	  overflow: auto;
	  /* background-color: red; */
  }
  .data_item{
  	  float: left;
  	  /* margin-right: 40px; */
	  margin-right: 10%;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  margin-bottom: 3%;
  	  height: 16px;
  	  display: flex;
  	  align-items: center;
  }
  .data_item:nth-child(2n){
  	  margin-right: 0;
  }
  .data_item div{
  	  display: inline-block;
  	  width: 100px;
  	  text-align:justify;
  	  height: 16px;
  	  line-height: 16px;
  	  
  }
  .data_item div i{
    display:inline-block;
  	height: 1px;
    width:100%;
  }
  .data_number{
  	  display: inline-block;
  	  min-width: 70px;
  	  margin-left: 10px;
  	  font-size: 15px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #FFFFFF;
  }
  
  .day_charts{
	  width: calc(35% - 30px);
	  height: 100%;
	  padding: 0 10px 0 20px;
	  box-sizing: border-box;
	  background: rgba(10, 23, 56, 0.7);
  }
  #myChart_ADataAnalysis1{
     width: 100%;
     height:calc(100% - 50px);
	 /* margin-top: 20px; */
   }
  #myChart_ADataAnalysis2{
      width: 100%;
      height:calc(100% - 50px);
   	 /* margin-top: 20px; */
    }
  #myChart_ADataAnalysis3{
     width: 100%;
     height:calc(100% - 50px);
  	 /* margin-top: 20px; */
   }
  #myChart_ADataAnalysis4{
     width: 100%;
     height:calc(100% - 50px);
  	 /* margin-top: 20px; */
   }
  #myChart_ADataAnalysis5{
     width: 100%;
     height:calc(100% - 50px);
  	 /* margin-top: 20px; */
   }
  .ny_data{
	  width: calc(35% - 30px);
	  height: 100%;
	  padding: 0 20px;
	  box-sizing: border-box;
	  background: rgba(10, 23, 56, 0.7);
  }
  
 /deep/.el-select{
 	  margin-right: 30px;
 }
 /deep/.el-select .el-input__inner{
 	  height: 48px;
 	  width: 150px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-select .el-input.is-focus .el-input__inner{
 	  border: 1px solid #36CFC9;
 }
 /deep/.el-input__inner{
 	  height: 48px;
 	  width: 200px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-input__suffix{
 	  color: #FFFFFF !important;
 }  
  
  
  /deep/.el-cascader .el-input .el-input__inner{
	  height: 40px;
	  width: 300px;
	  background-color: rgba(11,19,38,0.5);
	  background-size: cover;
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
 
 /deep/.el-date-editor .el-range__icon{
 	  color: #FFFFFF!important;
 	  margin-top: 5px;
   }
   /deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
 	  background-color: transparent;
 	  color: #FFFFFF !important;
 	  margin-left: 30px;
 	  
   }
   /deep/.el-input--prefix .el-input__inner{
   	 border: 1px solid #36CFC9;
   	 height: 40px;
 	 margin-top: 0;
 	 background-color: rgba(11,19,38,0.5);
 	 color: #FFFFFF !important;
   }
   /deep/.el-input__icon{
 	   color: #FFFFFF !important;
 	   /* margin-top: 2px; */
   }
</style>
<style>
/* // 级联选择器样式 */
/* .train-tree {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.train-tree .el-cascader-menu{
	color: #FFFFFF;
}
.train-tree .el-cascader-node:hover{
	background-color: rgba(0,0,0,1);
}
.train-tree .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
	color: #36CFC9;
} */
</style>



